



















































































































































import { Component, Vue } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import CardWrapper from '@/components/_uikit/CardWrapper.vue'

@Component({
  components: {
    CardWrapper,
  },
})
export default class Policy extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ПЕРСОНАЛЬНЫХ ДАННЫХ',
    }
  }
}
